











































import ProvinceForm from '@/components/province/ProvinceForm.vue';
import ProvinceUtils, { Province } from '@/utils/ProvinceUtils';
import Vue from 'vue';

export default Vue.extend({
  components: { ProvinceForm },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<Province>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async mounted() {
    try {
      this.item = ProvinceUtils.province.generateDefault(
        await ProvinceUtils.province.api.get(parseInt(this.$route.params?.id))
      );
      (this.$refs.form as any).resetValidation();
    } catch (error) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();

          this.item = ProvinceUtils.province.generateDefault(
            await ProvinceUtils.province.api.update(this.item)
          );

          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.edit', [this.$tc('province.label')]),
            type: 'success',
          });
        } catch (error) {
          this.$toast.push({
            text: this.$t('error.edit', [this.$tc('province.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },

    async handleDelete() {
      try {
        this.state.errors = undefined;
        this.state.delete = true;
        await ProvinceUtils.province.api.remove(this.item?.id as number);
        this.$toast.push({
          text: this.$t('success.delete', [this.$tc('province.label')]),
          type: 'success',
        });
        this.$router.replace({ name: 'province-list' });
      } catch (error) {
        this.$toast.push({
          text: this.$t('error.delete', [this.$tc('province.label')]),
          type: 'error',
        });
        this.state.errors = error; // TODO: handle errors
      } finally {
        this.state.delete = false;
      }
    },
  },
});
