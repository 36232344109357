






















import { locales } from '@/i18n';
import ProvinceUtils, { Province } from '@/utils/ProvinceUtils';
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: Object as () => Partial<Province>,
    },
    mode: {
      type: String as () => 'create' | 'edit',
      required: true,
    },
    'error-messages': {
      type: Object as () => { [key: string]: string[] },
      default: () => ({}),
    },
  },

  computed: {
    internal: {
      get(): Partial<Province> {
        return this.value || ProvinceUtils.province.generateDefault();
      },
      set(val: Partial<Province>) {
        this.$emit('input', val);
      },
    },

    locales() {
      return locales;
    },
  },
});
